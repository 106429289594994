<template>
  <div>
    <div class="row mb-2">
      <div class="col-sm-12 col-md-12">
        <h5>Имена файлов</h5>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="fileName"
            @change="SetUploadParams"
            name="uploadOptionFileName"
            value="default"
            class="mt-0"
          >
            По умолчанию
          </b-form-radio>
          <b-form-radio
            v-model="fileName"
            @change="SetUploadParams"
            name="uploadOptionFileName"
            value="user"
            class="mt-0"
          >
            Исходные
          </b-form-radio>
          <b-form-radio
            v-model="fileName"
            @change="SetUploadParams"
            name="uploadOptionFileName"
            value="kad"
            class="mt-0"
          >
            С кадастровым номером
          </b-form-radio>
        </div>
      </div>
    </div>
    <div class="row match-height custom-options-checkable">
      <div class="col-sm-12 col-md-12">
        <h5>Формат файлов</h5>
      </div>
      <div class="col-sm-12 col-md-12">
        <div class="demo-inline-spacing">
          <b-form-checkbox
            v-model="fileTypeHtml"
            @change="SetUploadParams"
            name="uploadOptionFileType"
            class="mt-0"
            disabled
          >
            HTML
          </b-form-checkbox>
          <b-form-checkbox
            v-model="fileTypePdf"
            @change="SetUploadParams"
            name="uploadOptionFileType"
            class="mt-0"
          >
            PDF
          </b-form-checkbox>
        </div>
      </div>
    </div>
    <div class="row match-height custom-options-checkable">
      <div class="col-sm-12 col-md-12">
        <div class="demo-inline-spacing mt-2">
          <b-form-checkbox
            v-model="isUploadArhive"
            @change="SetUploadParams"
            name="uploadOptionFileType"
            class="mt-0"
          >
            Автоматически добалять файлы в архив, файлы будут добавлены в Ваш личный кабинет в течении 24 часов
          </b-form-checkbox>
        </div>
      </div>
    </div>
    <div
      ref="dropRef"
      class="dropzone custom-dropzone mt-2"
    />

    <div class="card-body text-left p-0 mt-2">
      <div class="btn-downlod" style="margin-bottom: 5px;">
        <div @click.prevent="DownloadAll('html', 'session')" :class="'mr-1 btn btn-primary btn-sm waves-effect waves-float waves-light mt-0 py-0' + (!isHTMLSession ? ' disabled' : '')"><svg style="width: 25px; cursor: pointer;" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><path fill="white" d="M107 104V84c0-1.7-1.3-3-3-3s-3 1.3-3 3v17H27V84c0-1.7-1.3-3-3-3s-3 1.3-3 3v20c0 1.7 1.3 3 3 3h80C105.7 107 107 105.7 107 104zM64 21c-1.7 0-3 1.3-3 3v42.8l-9.9-9.9c-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0 4.2l15 15c.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9l15-15c1.2-1.2 1.2-3.1 0-4.2-1.2-1.2-3.1-1.2-4.2 0L67 66.8V24C67 22.3 65.7 21 64 21z"></path></svg> HTML за сессию</div>
        <div @click.prevent="DownloadAll('html', 'day')" :class="'mr-1 btn btn-primary btn-sm waves-effect waves-float waves-light mt-0 py-0' + (!isHTMLDay ? ' disabled' : '')"><svg style="width: 25px; cursor: pointer;" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><path fill="white" d="M107 104V84c0-1.7-1.3-3-3-3s-3 1.3-3 3v17H27V84c0-1.7-1.3-3-3-3s-3 1.3-3 3v20c0 1.7 1.3 3 3 3h80C105.7 107 107 105.7 107 104zM64 21c-1.7 0-3 1.3-3 3v42.8l-9.9-9.9c-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0 4.2l15 15c.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9l15-15c1.2-1.2 1.2-3.1 0-4.2-1.2-1.2-3.1-1.2-4.2 0L67 66.8V24C67 22.3 65.7 21 64 21z"></path></svg> HTML за сегодня</div>
      </div>
      <div class="btn-downlod" style="margin-bottom: 5px;">
        <div @click.prevent="DownloadAll('pdf', 'session')" :class="'mr-1 btn btn-primary btn-sm waves-effect waves-float waves-light mt-0 py-0' + (!isPDFSession ? ' disabled' : '')"><svg style="width: 25px; cursor: pointer;" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><path fill="white" d="M107 104V84c0-1.7-1.3-3-3-3s-3 1.3-3 3v17H27V84c0-1.7-1.3-3-3-3s-3 1.3-3 3v20c0 1.7 1.3 3 3 3h80C105.7 107 107 105.7 107 104zM64 21c-1.7 0-3 1.3-3 3v42.8l-9.9-9.9c-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0 4.2l15 15c.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9l15-15c1.2-1.2 1.2-3.1 0-4.2-1.2-1.2-3.1-1.2-4.2 0L67 66.8V24C67 22.3 65.7 21 64 21z"></path></svg> PDF за сессию</div>
        <div @click.prevent="DownloadAll('pdf', 'day')" :class="'mr-1 btn btn-primary btn-sm waves-effect waves-float waves-light mt-0 py-0' + (!isHTMLDay ? ' disabled' : '')"><svg style="width: 25px; cursor: pointer;" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><path fill="white" d="M107 104V84c0-1.7-1.3-3-3-3s-3 1.3-3 3v17H27V84c0-1.7-1.3-3-3-3s-3 1.3-3 3v20c0 1.7 1.3 3 3 3h80C105.7 107 107 105.7 107 104zM64 21c-1.7 0-3 1.3-3 3v42.8l-9.9-9.9c-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0 4.2l15 15c.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9l15-15c1.2-1.2 1.2-3.1 0-4.2-1.2-1.2-3.1-1.2-4.2 0L67 66.8V24C67 22.3 65.7 21 64 21z"></path></svg> PDF за сегодня</div>
      </div>
      <p class="text-body mt-1 mb-0">
        В результате конвертации Вы получите Выписку из ЕГРН в формате - PDF или HTML.
        Вы сможете просмотреть ее онлайн, скачать или распечатать на принтере.<br>
        <b>HTML (Web) формат можно скачать, открыть в любом браузере и так же как PDF отправить по почте или распечатать на принтере.</b>
      </p>
    </div>
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  defineComponent,
  onUnmounted,
} from '@vue/composition-api'
import { Dropzone } from 'dropzone'
import useJwt from '@/auth/jwt/useJwt'
import {
  BFormRadio, BFormCheckbox,
} from 'bootstrap-vue'
import axios from '@axios'

export default defineComponent({
  name: 'Dropzone',
  components: {
    BFormRadio, BFormCheckbox,
  },
  setup() {
    const urlConverter = 'https://myegrn.ru'
    const fileName = ref('user')
    const fileTypeHtml = ref(true)
    const fileTypePdf = ref(false)
    const isUploadArhive = ref(true)
    const sessionFileList = ref([])

    const isHTMLSession = ref(false)
    const isHTMLDay = ref(false)
    const isHTMLAll = ref(false)
    const isPDFSession = ref(false)
    const isPDFDay = ref(false)
    // const token = ref('')

    const userData = JSON.parse(localStorage.getItem('userData'))

    const GetAnyMyFiles = () => {
      axios.post(`${urlConverter}/files/AnyMyFiles`, { token: userData.convertertoken }).then(data => {
        isHTMLAll.value = data.data.isHTMLAll
        isHTMLDay.value = data.data.isHTMLDay
        isPDFDay.value = data.data.isPDFDay
      })
    }

    GetAnyMyFiles()

    /*
    const GetToken = () => {
      try {
        token.value = localStorage.getItem('token')
        if (token.value == null || token.value === '') {
          axios.post('${urlConverter}/jwt/gettokenconverter')
            .then(data => {
              token.value = data.data.Data.token
              localStorage.setItem('token', data.data.Data.token)
              GetAnyMyFiles()
            })
        } else {
          GetAnyMyFiles()
        }
        return token
      } catch (ex) {
        console.error(ex)
        return ''
      }
    }
    */

    // eslint-disable-next-line no-shadow
    const download = (fileUrl, fileName) => {
      const a = document.createElement('a')
      a.href = fileUrl
      a.setAttribute('download', fileName)
      a.click()
      a.remove()
    }

    const DownloadAll = (type, time) => {
      const values = {
        token: userData.convertertoken,
        type,
        time,
        session: sessionFileList,
      }
      axios.post(`${urlConverter}/files/DownloadAllFiles`, values)
        .then(data => {
          // eslint-disable-next-line no-param-reassign
          data = data.data
          if (data.stat === 'ALSOK' && data.file) {
            const sp = data.file.split('/')
            download(`${urlConverter}/${data.file}`, sp[sp.length - 1])
          }
        })
    }

    const GetUploadParams = () => {
      const optionFilename = localStorage.getItem('option_filename')
      const optionType = localStorage.getItem('option_type')
      isUploadArhive.value = localStorage.getItem('isUploadArhive') !== 'false'
      if (optionFilename !== '') {
        fileName.value = optionFilename
      }
      if (optionType !== '') {
        fileTypePdf.value = true
      } else {
        fileTypePdf.value = false
      }
    }
    GetUploadParams()
    const SetUploadParams = () => {
      localStorage.setItem('option_filename', fileName.value)
      localStorage.setItem('option_type', fileTypePdf.value ? 'pdf' : '')
      localStorage.setItem('isUploadArhive', isUploadArhive.value)
    }
    const GetUploadUrl = () => {
      let url = `/home/upload/?option_filename=${fileName.value}`
      if (fileTypePdf.value) {
        url += '&option_type=pdf'
      }
      debugger
      if (isUploadArhive.value) {
        url += '&isuploadarhive=true'
      }
      SetUploadParams()
      return urlConverter + url
    }
    const dropRef = ref(null)
    let dz = ref(null)
    onMounted(() => {
      // GetToken()
      if (dropRef.value !== null) {
        dz = new Dropzone(dropRef.value,
          {
            url: `${urlConverter}`,
            paramName: 'file', // The name that will be used to transfer the file
            maxFilesize: 128.6, // MB
            acceptedFiles: '.xml,.zip',
            timeout: 1200000,
            parallelUploads: 10,
            createImageThumbnails: false,
            headers: {
              // eslint-disable-next-line prefer-template
              Authorization: 'Bearer ' + useJwt.getToken(),
              'X-CSRF-Token': userData.convertertoken,
            },
            dictDefaultMessage: 'Перетащите сюда файлы для загрузки.',
            dictFallbackMessage: 'Ваш браузер не поддерживает загрузку файлов перетаскиванием.',
            dictFallbackText: 'Воспользуйтесь резервной формой ниже, чтобы загрузить файлы, как в старые времена.',
            dictFileTooBig: 'Файл слишком большой ({{filesize}}MiB). Максимальный размер файла: {{maxFilesize}}MiB.',
            dictInvalidFileType: 'Вы не можете загружать файлы этого типа.',
            dictResponseError: 'Сервер ответил кодом {{statusCode}}.',
            dictCancelUpload: 'Отменить загрузку.',
            dictUploadCanceled: 'Загрузка отменена.',
            dictCancelUploadConfirmation: 'Вы уверены, что хотите отменить эту загрузку?',
            dictRemoveFile: 'Удалить файл',
            dictMaxFilesExceeded: 'Вы не можете больше загружать файлы.',
            success: (file, fullData) => {
              const data = fullData
              if (data.stat === 'ALSOK') {
                const pr = file.previewElement.getElementsByClassName('dz-details')[0]
                const fn = file.previewElement.getElementsByClassName('dz-filename')[0]
                const fntext = fn.innerText
                const pdfHtml = data.sourcePDF ? `<a class="btn btn-default" data-tag="pdf_open" target="_blank" href="${urlConverter}/${data.sourcePDF}">PDF</a>`
                    + `<a class="btn btn-default" data-tag="pdf_download" download href="${urlConverter}/${data.sourcePDF}"><svg style="width: 25px; cursor: pointer;" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><path d="M107 104V84c0-1.7-1.3-3-3-3s-3 1.3-3 3v17H27V84c0-1.7-1.3-3-3-3s-3 1.3-3 3v20c0 1.7 1.3 3 3 3h80C105.7 107 107 105.7 107 104zM64 21c-1.7 0-3 1.3-3 3v42.8l-9.9-9.9c-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0 4.2l15 15c.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9l15-15c1.2-1.2 1.2-3.1 0-4.2-1.2-1.2-3.1-1.2-4.2 0L67 66.8V24C67 22.3 65.7 21 64 21z"></path></svg></a>`
                    + '| ' : ''
                let htmlHtml = ''
                if (data.sourceHTML) {
                  htmlHtml = `<a class="btn btn-default"  data-tag="html_open" target="_blank" href="${urlConverter}/${data.sourceHTML}">HTML</a>`
                        + `<a class="btn btn-default" data-tag="html_download" download href="${urlConverter}/${data.sourceHTML}">`
                        + '<svg style="width: 25px; cursor: pointer;" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><path d="M107 104V84c0-1.7-1.3-3-3-3s-3 1.3-3 3v17H27V84c0-1.7-1.3-3-3-3s-3 1.3-3 3v20c0 1.7 1.3 3 3 3h80C105.7 107 107 105.7 107 104zM64 21c-1.7 0-3 1.3-3 3v42.8l-9.9-9.9c-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0 4.2l15 15c.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9l15-15c1.2-1.2 1.2-3.1 0-4.2-1.2-1.2-3.1-1.2-4.2 0L67 66.8V24C67 22.3 65.7 21 64 21z"></path></svg>'
                        + '</a>'
                        + `<a class="btn btn-default" data-tag="html_print" target="_blank" href="${urlConverter}/${data.sourceHTML}">`
                        + '<svg style="width: 20px; cursor: pointer;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M 11 2 L 11 15 L 5 15 C 3.3550302 15 2 16.35503 2 18 L 2 36 C 2 37.64497 3.3550302 39 5 39 L 11 39 L 11 48 L 39 48 L 39 39 L 45 39 C 46.64497 39 48 37.64497 48 36 L 48 18 C 48 16.35503 46.64497 15 45 15 L 39 15 L 39 2 L 11 2 z M 13 4 L 37 4 L 37 15 L 13 15 L 13 4 z M 5 17 L 11.832031 17 A 1.0001 1.0001 0 0 0 12.158203 17 L 37.832031 17 A 1.0001 1.0001 0 0 0 38.158203 17 L 45 17 C 45.56503 17 46 17.43497 46 18 L 46 36 C 46 36.56503 45.56503 37 45 37 L 39 37 L 39 28 L 38 28 L 11 28 L 11 37 L 5 37 C 4.4349698 37 4 36.56503 4 36 L 4 18 C 4 17.43497 4.4349698 17 5 17 z M 41 20 A 2 2 0 0 0 39 22 A 2 2 0 0 0 41 24 A 2 2 0 0 0 43 22 A 2 2 0 0 0 41 20 z M 13 30 L 37 30 L 37 37.832031 A 1.0001 1.0001 0 0 0 37 38.158203 L 37 46 L 13 46 L 13 38.167969 A 1.0001 1.0001 0 0 0 13 37.841797 L 13 30 z M 17 33 A 1.0001 1.0001 0 1 0 17 35 L 33 35 A 1.0001 1.0001 0 1 0 33 33 L 17 33 z M 17 37 A 1.0001 1.0001 0 1 0 17 39 L 29 39 A 1.0001 1.0001 0 1 0 29 37 L 17 37 z M 17 41 A 1.0001 1.0001 0 1 0 17 43 L 33 43 A 1.0001 1.0001 0 1 0 33 41 L 17 41 z"></path></svg>'
                        + '</a>'
                        + `<a class="btn btn-default" data-tag="html_view" target="_blank" href="${urlConverter}/${data.sourceHTML}">`
                        + '<svg style="width: 20px; cursor: pointer;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M 4 0 L 4 48 L 28 48 C 28.359375 48.003906 28.695313 47.816406 28.878906 47.503906 C 29.058594 47.191406 29.058594 46.808594 28.878906 46.496094 C 28.695313 46.183594 28.359375 45.996094 28 46 L 6 46 L 6 2 L 24 2 L 24 16 L 38 16 L 38 23 C 37.996094 23.359375 38.183594 23.695313 38.496094 23.878906 C 38.808594 24.058594 39.191406 24.058594 39.503906 23.878906 C 39.816406 23.695313 40.003906 23.359375 40 23 L 40 14.59375 L 39.71875 14.28125 L 25.71875 0.28125 L 25.40625 0 Z M 26 3.4375 L 36.5625 14 L 26 14 Z M 36 26 C 30.488281 26 26 30.488281 26 36 C 26 41.511719 30.488281 46 36 46 C 38.394531 46 40.589844 45.160156 42.3125 43.75 L 48.28125 49.71875 L 49.71875 48.28125 L 43.75 42.3125 C 45.160156 40.589844 46 38.394531 46 36 C 46 30.488281 41.511719 26 36 26 Z M 36 28 C 40.429688 28 44 31.570313 44 36 C 44 40.429688 40.429688 44 36 44 C 31.570313 44 28 40.429688 28 36 C 28 31.570313 31.570313 28 36 28 Z"></path></svg>'
                        + '</a>'
                        + ' | '
                }

                // eslint-disable-next-line no-useless-concat, prefer-template
                const html = '<div>' + pdfHtml + htmlHtml + '(' + data.ms + ' sec)</div>' + pr.innerHTML + fntext
                pr.innerHTML = html
                if (pr.getElementsByClassName('dz-filename') && pr.getElementsByClassName('dz-filename').length > 0) {
                  // console.log(pr.getElementsByClassName('dz-filename'))
                  pr.getElementsByClassName('dz-filename')[0].remove()
                }
              } else {
                const pr = file.previewElement.getElementsByClassName('dz-details')[0]
                const fn = file.previewElement.getElementsByClassName('dz-filename')[0]
                const fntext = fn.innerText
                const pdfHtml = data.sourcePDF ? `<a class="btn btn-default" data-tag="pdf_open" target="_blank" href="${urlConverter}/${data.sourcePDF}">PDF</a>`
                    + `<a class="btn btn-default" data-tag="pdf_download" download href="${urlConverter}/${data.sourcePDF}"><svg style="width: 25px; cursor: pointer;" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><path d="M107 104V84c0-1.7-1.3-3-3-3s-3 1.3-3 3v17H27V84c0-1.7-1.3-3-3-3s-3 1.3-3 3v20c0 1.7 1.3 3 3 3h80C105.7 107 107 105.7 107 104zM64 21c-1.7 0-3 1.3-3 3v42.8l-9.9-9.9c-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0 4.2l15 15c.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9l15-15c1.2-1.2 1.2-3.1 0-4.2-1.2-1.2-3.1-1.2-4.2 0L67 66.8V24C67 22.3 65.7 21 64 21z"></path></svg></a>`
                    + '| ' : ''
                let htmlHtml = ''
                if (data.sourceHTML) {
                  htmlHtml = `<a class="btn btn-default"  data-tag="html_open" target="_blank" href="${urlConverter}/${data.sourceHTML}">HTML</a>`
                        + `<a class="btn btn-default" data-tag="html_download" download href="${urlConverter}/${data.sourceHTML}">`
                        + '<svg style="width: 25px; cursor: pointer;" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><path d="M107 104V84c0-1.7-1.3-3-3-3s-3 1.3-3 3v17H27V84c0-1.7-1.3-3-3-3s-3 1.3-3 3v20c0 1.7 1.3 3 3 3h80C105.7 107 107 105.7 107 104zM64 21c-1.7 0-3 1.3-3 3v42.8l-9.9-9.9c-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0 4.2l15 15c.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9l15-15c1.2-1.2 1.2-3.1 0-4.2-1.2-1.2-3.1-1.2-4.2 0L67 66.8V24C67 22.3 65.7 21 64 21z"></path></svg>'
                        + '</a>'
                        + `<a class="btn btn-default" data-tag="html_print" target="_blank" href="${urlConverter}/${data.sourceHTML}">`
                        + '<svg style="width: 20px; cursor: pointer;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M 11 2 L 11 15 L 5 15 C 3.3550302 15 2 16.35503 2 18 L 2 36 C 2 37.64497 3.3550302 39 5 39 L 11 39 L 11 48 L 39 48 L 39 39 L 45 39 C 46.64497 39 48 37.64497 48 36 L 48 18 C 48 16.35503 46.64497 15 45 15 L 39 15 L 39 2 L 11 2 z M 13 4 L 37 4 L 37 15 L 13 15 L 13 4 z M 5 17 L 11.832031 17 A 1.0001 1.0001 0 0 0 12.158203 17 L 37.832031 17 A 1.0001 1.0001 0 0 0 38.158203 17 L 45 17 C 45.56503 17 46 17.43497 46 18 L 46 36 C 46 36.56503 45.56503 37 45 37 L 39 37 L 39 28 L 38 28 L 11 28 L 11 37 L 5 37 C 4.4349698 37 4 36.56503 4 36 L 4 18 C 4 17.43497 4.4349698 17 5 17 z M 41 20 A 2 2 0 0 0 39 22 A 2 2 0 0 0 41 24 A 2 2 0 0 0 43 22 A 2 2 0 0 0 41 20 z M 13 30 L 37 30 L 37 37.832031 A 1.0001 1.0001 0 0 0 37 38.158203 L 37 46 L 13 46 L 13 38.167969 A 1.0001 1.0001 0 0 0 13 37.841797 L 13 30 z M 17 33 A 1.0001 1.0001 0 1 0 17 35 L 33 35 A 1.0001 1.0001 0 1 0 33 33 L 17 33 z M 17 37 A 1.0001 1.0001 0 1 0 17 39 L 29 39 A 1.0001 1.0001 0 1 0 29 37 L 17 37 z M 17 41 A 1.0001 1.0001 0 1 0 17 43 L 33 43 A 1.0001 1.0001 0 1 0 33 41 L 17 41 z"></path></svg>'
                        + '</a>'
                        + `<a class="btn btn-default" data-tag="html_view" target="_blank" href="${urlConverter}/${data.sourceHTML}">`
                        + '<svg style="width: 20px; cursor: pointer;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M 4 0 L 4 48 L 28 48 C 28.359375 48.003906 28.695313 47.816406 28.878906 47.503906 C 29.058594 47.191406 29.058594 46.808594 28.878906 46.496094 C 28.695313 46.183594 28.359375 45.996094 28 46 L 6 46 L 6 2 L 24 2 L 24 16 L 38 16 L 38 23 C 37.996094 23.359375 38.183594 23.695313 38.496094 23.878906 C 38.808594 24.058594 39.191406 24.058594 39.503906 23.878906 C 39.816406 23.695313 40.003906 23.359375 40 23 L 40 14.59375 L 39.71875 14.28125 L 25.71875 0.28125 L 25.40625 0 Z M 26 3.4375 L 36.5625 14 L 26 14 Z M 36 26 C 30.488281 26 26 30.488281 26 36 C 26 41.511719 30.488281 46 36 46 C 38.394531 46 40.589844 45.160156 42.3125 43.75 L 48.28125 49.71875 L 49.71875 48.28125 L 43.75 42.3125 C 45.160156 40.589844 46 38.394531 46 36 C 46 30.488281 41.511719 26 36 26 Z M 36 28 C 40.429688 28 44 31.570313 44 36 C 44 40.429688 40.429688 44 36 44 C 31.570313 44 28 40.429688 28 36 C 28 31.570313 31.570313 28 36 28 Z"></path></svg>'
                        + '</a>'
                        + ' | '
                }

                // eslint-disable-next-line no-useless-concat, prefer-template
                const html = '<div>' + pdfHtml + htmlHtml + '(' + data.ms + ' sec)</div>' + pr.innerHTML + fntext
                pr.innerHTML = html
                if (pr.getElementsByClassName('dz-filename') && pr.getElementsByClassName('dz-filename').length > 0) {
                  // console.log(pr.getElementsByClassName('dz-filename'))
                  pr.getElementsByClassName('dz-filename')[0].remove()
                }
                if (data.msg.indexOf('<!DOCTYPE html>') !== -1) {
                  // eslint-disable-next-line padded-blocks,no-param-reassign
                  data.msg = 'Файл не корректный, пожалуйста загрузите исходники выписки полученные от Росреестра'
                }
                file.previewElement.classList.add('dz-error')
              }

              sessionFileList.value.push(data.hash)

              if (data.sourceHTML) {
                isHTMLSession.value = true
                isHTMLDay.value = true
                isHTMLAll.value = true
              }
              if (data.sourcePDF) {
                isPDFSession.value = true
                isPDFDay.value = true
              }
            },
            error: (file, msg) => {
              // console.log('error', msg)
              let localMsg = msg.ExceptionMessage ? msg.ExceptionMessage : msg
              if (localMsg.indexOf('<!DOCTYPE html>') !== -1) {
                localMsg = 'Файл не корректный, пожалуйста загрузите исходники выписки полученные от Росреестра'
              }
              const pr = file.previewElement.getElementsByClassName('dz-details')[0]
              const fn = pr.getElementsByClassName('dz-filename')[0]
              const fntext = fn.innerText
              const html = pr.innerHTML + fntext
              pr.innerHTML = html
              if (pr.getElementsByClassName('dz-filename') && pr.getElementsByClassName('dz-filename').length > 0) {
                pr.getElementsByClassName('dz-filename')[0].remove()
              }
              file.previewElement.classList.add('dz-error')
              const errText = file.previewElement.querySelectorAll('span[data-dz-errormessage]')[0]
              errText.innerText = localMsg
            },
          })
        dz.on('processing', file => {
          console.log(file, this)
          dz.options.url = GetUploadUrl()
        })
      }
    })
    onUnmounted(() => {
      dz.destroy()
    })
    return {
      dropRef,
      fileName,
      fileTypeHtml,
      fileTypePdf,
      SetUploadParams,

      isHTMLSession,
      isHTMLDay,
      isHTMLAll,
      isPDFSession,
      isPDFDay,

      DownloadAll,
      isUploadArhive,
    }
  },
})
</script>

<style>
.dropzone .dz-preview {
        width: 100%;
        margin: 0px !important;
        border-bottom: 2px dashed #7367f02e !important;
        padding: 2px;
        min-height: 30px;
    }

        .dropzone .dz-preview:last-child {
            border-bottom: none;
        }

    .dz-image {
        display: none !important;
    }

    .dropzone .dz-preview .dz-details {
        position: relative;
        top: unset;
        left: unset;
        right: unset;
        text-align: left !important;
        font-size: 14px;
    }

    .dropzone .dz-preview:not(.dz-processing) .dz-progress {
        -webkit-animation: none;
        -moz-animation: none;
        -ms-animation: none;
        -o-animation: none;
        animation: none;
    }

    .dz-details div {
        display: inline-block;
    }

    .dz-error-message {
        position: relative !important;
        border-radius: unset !important;
        top: unset !important;
        left: unset !important;
        width: 100% !important;
    }

    .dropzone .dz-preview .dz-details {
        padding: 0px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .dropzone .dz-preview .dz-error-message {
        opacity: 1 !important;
        z-index: 996;
    }

        .dropzone .dz-preview .dz-error-message:after {
            display: none;
        }

    .dropzone .dz-preview .dz-details .dz-filename:hover {
        overflow: unset;
        text-overflow: ellipsis;
    }

    .dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
        overflow: unset;
    }

    .dropzone .dz-preview .dz-details .dz-size {
        margin-bottom: 0px;
    }

    .dropzone .dz-preview.dz-complete .dz-progress {
        display: none;
    }

    .dropzone .dz-preview .dz-success-mark, .dropzone .dz-preview .dz-error-mark {
        pointer-events: none;
        z-index: 500;
        position: absolute;
        display: block;
        top: 33px;
        left: 8px;
        margin-left: -27px;
        margin-top: -27px;
    }

        .dropzone .dz-preview .dz-success-mark svg, .dropzone .dz-preview .dz-error-mark svg {
            width: 40px !important;
            height: 40px !important;
        }

    .dropzone .dz-preview .dz-details .btn {
        padding-left: 5px;
        padding-right: 5px;
    }

    .dropzone .dz-preview .dz-progress {
        z-index: 1000;
        pointer-events: none;
        position: absolute;
        height: 6px;
        left: unset;
        bottom: -2px;
        top: unset;
        margin-bottom: 0px;
        width: 100%;
        margin-left: unset;
    }

        .dropzone .dz-preview .dz-progress .dz-upload {
            background-image: linear-gradient( 45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
            background-size: .75rem .75rem;
            -webkit-animation: progress-bar-stripes 1s linear infinite;
            animation: progress-bar-stripes 1s linear infinite;
            background-color: #7367F0;
            color: #fff;
        }

   @-webkit-keyframes progress-bar-stripes {
        from {
            background-position: .75rem 0
        }

        to {
            background-position: 0 0
        }
    }

    @keyframes progress-bar-stripes {
        from {
            background-position: .75rem 0
        }

        to {
            background-position: 0 0
        }
    }

    .custom-options-checkable .custom-option-item {
    width: 100%;
    cursor: pointer;
    border-radius: .42rem;
    color: #82868B;
    background-color: rgba(130,134,139,.06);
    border: 1px solid #EBE9F1
}

    .custom-options-checkable .custom-option-item .custom-option-item-title {
        color: #82868B
    }

.custom-option-item-check, .group-option-item-check {
    position: absolute;
    clip: rect(0,0,0,0)
}

    .custom-option-item-check:checked + .custom-option-item, .group-option-item-check:checked + .custom-option-item {
        color: #7367F0;
        background-color: rgba(115,103,240,.12);
        border-color: #7367F0
    }

        .custom-option-item-check:checked + .custom-option-item .custom-option-item-title, .group-option-item-check:checked + .custom-option-item .custom-option-item-title {
            color: #7367F0
        }
</style>
